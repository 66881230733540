body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px; /* Default font size for larger screens */
}

/* Extra-small devices (portrait phones) */
@media (max-width: 600px) {
  html {
    font-size: 18px; /* Slightly smaller font size for small screens */
  }
}

/* Small devices (landscape phones) */
@media (min-width: 600px) and (max-width: 900px) {
  html {
    font-size: 14px; /* Default font size for small tablets */
  }
}

/* Medium devices (tablets) */
@media (min-width: 900px) and (max-width: 1200px) {
  html {
    font-size: 16px; /* Larger font size for medium screens */
  }
}

/* Large devices (desktops) */
@media (min-width: 1200px) and (max-width: 1536px) {
  html {
    font-size: 18px; /* Larger font size for large screens */
  }
}

/* Extra-large devices (large desktops) */
@media (min-width: 1536px) {
  html {
    font-size: 20px; /* Largest font size for extra-large screens */
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
